.main{
 width: 100vw;
 overflow-x: hidden !important;
}

@mixin xsBreakPoint{
    @media (max-width: 575.98px) {
      @content;
    }
  }
  
  @mixin smBreakPoint{
    @media (min-width: 576px) and (max-width: 767.98px) {
      @content;
    }
  }
  
  @mixin mdBreakPoint{
    @media (min-width: 768px) and (max-width: 991.98px) {
      @content;
    }
  }
  
  @mixin lgBreakPoint{
    @media (min-width: 992px) and (max-width: 1199.98px) {
      @content;
    }
  }
  
  @mixin xlBreakPoint{
    @media (min-width: 1200px) {
      @content;
    }
  }


  @media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-inner > .item {
      -webkit-transition: -webkit-transform .6s ease-in-out;
           -o-transition:      -o-transform .6s ease-in-out;
              transition:         transform .6s ease-in-out;
  
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      -webkit-perspective: 1000;
              perspective: 1000;
    }
    .carousel-inner > .item.next,
    .carousel-inner > .item.active.right {
      left: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
    .carousel-inner > .item.prev,
    .carousel-inner > .item.active.left {
      left: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
    .carousel-inner > .item.next.left,
    .carousel-inner > .item.prev.right,
    .carousel-inner > .item.active {
      left: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  