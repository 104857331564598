@use '../../App.scss' as *;


.parallax1 {
  align-items: flex-end;
  justify-content: flex-start;
  display: flex;
    flex-wrap: wrap;
    background-size: cover;
    width: 100vw;
    height: calc(100vh - 96px);
    background-attachment: fixed ;
    background-position: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url('../../images/bnb_bg_1.jpeg');
   // background-color: whitesmoke;
    background-repeat: no-repeat;
    overflow: hidden !important;

    @include xsBreakPoint{
      background-position: left;
      background-image: url('../../images/bnb_bg_1.jpeg');
      background-attachment:scroll;
    
    }
  
.header-container{
  align-items: flex-start;
  height: 70%;
  width: 40%;

  @include xsBreakPoint{
    align-items: center;
    height: 100%;
    width: 60%;
  }
  
 // background-color: aqua;
}


.header-text{
  padding: 0 0 0 10px;
  text-align: left;
  color: white;
  font-size: 1.4vw;
  font-weight: 900;
  // align-items: justify;
  // justify-content: justify;

  // @include mdBreakPoint{
  //   font-size: 1rem;
  // }

  // @include smBreakPoint{
  //   font-size: 1rem;
  // }

  @include xsBreakPoint{
   font-size: 1rem;
  
  }
  
}

}