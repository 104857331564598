@use '../../App.scss' as *;

.parallax2 {
   
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url('../../images/parallax_2.jpg');
    height: 100vh;
    width: 100%;
    background-attachment: fixed ;
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;

    @include xsBreakPoint{
      height: auto !important;
      //background-position: left;
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url('../../images/parallax_2.jpg');
      background-attachment:scroll;
      background-position: center; 
      background-size: cover;

    }
  }

.bandMembers {
    height: 300px;
    width: 120px;
    object-fit: cover;
}

.about-text-aligning-white {
  text-align:center;
  color:white;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
