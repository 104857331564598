@use '../../App.scss' as *;


.footer-content{
 height: 60%;

 @include xsBreakPoint{
     height: auto !important;
 }
}

.newsletter{
    @include xsBreakPoint{
        height: auto;
    }
}

.icons-display-blueBird{
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.parallax5 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url('../../images/parallax_3.jpg');
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain !important;

    @include xsBreakPoint{
        height: auto;
        background-position: center center;
        background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url('../../images/parallax_3.jpg');
        background-attachment:scroll;
        
      }
  }


.subscribe input:hover {
    box-shadow: 2px 3px 10px 2px rgba(97, 14, 14, 0.468);
}


.Footer-Links{
    font-size: large;
    color: white;

    .headings{
        font-size: xx-large;
        margin:0% !important;
    }
}

.items{

    display: flex;
    flex-direction: column;

    p,a{
        margin: 0 0 15px 0;
    }

    a{
        padding-left: 20px;
        color: white;
        text-decoration: none;
    }
    a:hover{
        color: white;
        text-decoration: none;
    }

}



.accordion-item, .accordion-button, .accordion-header, .collapsed, 
.accordion-button:active, .accordion-button:focus, .toggle, .accordion-button:hover, 
.accordion-header:active, .accordion-item:active, .accordion-item:focus, .accordion-header:focus, .accordion{
background: transparent !important;
color: white !important;
border: none !important;
box-shadow: none !important;
}

.accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}



