@use '../../App.scss' as *;

#gallery {
    padding: 15px;
}

.Gallery-Section{
    height: 100vh;
    
    @include xsBreakPoint{
        height: auto;
    }
}

.gallery-img{
width:23%;
height:33vh;
// border:1px solid red;
img{
    width:100%;
    height:100%;
    object-fit: cover;
}
}
.gallery-parent{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;

}

