@use "../../App.scss" as *;

.parallax4 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../../images/carousel_bg.jpeg");
  height: 67%;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;

  @include xsBreakPoint {
    height: auto;
    background-position: left;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../../images/carousel_bg.jpeg");
    background-attachment: scroll;
  }
}

.contactus-section {
  height: 120vh;
  width: 100%;

  @include xsBreakPoint {
    height: auto;
  }
}

.sponsors-section {
  height: 33%;

  @include xsBreakPoint {
    height: 30%;
  }
}

input,
textarea {
  height: 50px;
  width: 30%;
  margin-bottom: 20px;
  padding-top: 4px;
  padding-right: 6px;
  border-radius: 4px;
  box-shadow: 2px 3px 10px 2px rgba(0, 0, 0, 0.16);
  border-color: transparent;
  color: #111;

  @include xsBreakPoint {
    width: 90% !important;
  }
}

input:focus,
textarea:focus {
  outline: none !important;
}

.btn-danger {
  font-size: 18px;
  background-color: #e03114;
  background-image: none;
  padding: 20px 50px;
  border: transparent;
  border-radius: 8px;
  text-shadow: 2px;
}

.btn-danger:focus {
  box-shadow: none !important;
}

textarea {
  height: 129px;
  width: 30%;
}

.sponsor-display {
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  object-fit: contain;
}
