@use '../../App.scss' as *;

.parallax3 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.6)),url('../../images/animation_bg.jpg');
    height: 110vh;
    width: 100%;
    background-attachment: fixed !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    z-index: -1;

    @include xsBreakPoint{
      background-position: center;
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url('../../images/animation_bg.jpg');
      background-attachment: scroll !important;
    }
  }

.container{
  height: 100%;
}

.section1{
  height: 60%;
  width: 100%;
  position: relative;

  .services-main-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    width: 100%;
    font-weight: bold;
    font-size: medium;
    margin: auto;
    
    @include xsBreakPoint{
      font-size: small;
    }
  }
}


.section1 #ImageFrame{
  width: 50%;
  z-index: 1;

  @include xsBreakPoint{
    width: 90%;
  }
}

.mini-icon{
  width: 30%;
  max-width: 250px;    
}

.section2{
  
  position: relative;

  @include xsBreakPoint{
    padding: 0 20px;
   
  }

  .services-header{
    position: relative;
    overflow: hidden;
    height: 90%;

    &:focus{
      outline: 0 !important;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; 
    }

    &:hover::before{
      background: rgba(0,0,0,0.7);
      content: "";
      position: absolute;
      width: 90%;
      height: 101%;
      top: 50%;
      left: 50%;
      font-weight: bold;
      transform: translate(-50%,-50%);
    }

    .services-content{
      position: absolute;
      color: white;
      height: 50%;
      transform: translate(-50%,-50%);
      top: 50%;
      left: 50%;
      font-weight: bold;

      @include xsBreakPoint{
        height: 90%;
        font-size: small;
      }
    }
  }
}

