h3 {
    margin-top: 0px !important;
    padding-top: 30px !important;
    font-weight: bold;
    text-align: center;
}

.text-aligning-black {
    text-align:center;
    color:black;
    padding-bottom: 30px;
}

.text-aligning-white {
    text-align:center;
    color:white;
    padding-bottom: 30px;
}
