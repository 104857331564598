nav{
    background-color: #080c19;
}

.navbar-brand{
    height: 80px !important;
    width: 80px !important;
}


.nav-link{
    font-size: 17px;
}

.nav-link:hover, .nav-link:active{
    color: #fff !important;
}

.navbar-dark .navbar-nav .nav-link{
     color: whitesmoke;
   }