@use '../../App.scss' as *;

.concertName{
    color:#ba091a;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}

button:focus, button:hover, button:active{
    outline: none !important;
}

.btn{
    font-size: 14px;
    padding: 5px 18px;
    color: #fff;
    background-color: #8b8b8b;
    border:2px solid #8b8b8b !important;
    
}

.btn:focus{
    box-shadow: none;
   
}

.show-all-btn {
    background-color: transparent;
    background-image: none;
    border:2px solid #8b8b8b !important;
    border-radius: 3px;
    padding: 8px 10px;
    margin-top: 30px;
    color: #666;
    text-align: center;
    margin-bottom: 30;
    width: 170px !important;
}

#eventsSelection{
    height:170px;
    overflow: hidden;
    transition: all 300ms ease-in-out;

    @include xsBreakPoint{
        height: 550px;
    }
    
}
.showButton {
    height: 350px !important;
    
    @include xsBreakPoint{
        height: 1050px !important;
    }
}


.showDisplay
{
    transition: all .5s ease-in-out;
}

.hideDisplay
{
    display: none;
}



